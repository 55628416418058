import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Logo from "../components/Logo";
import "../styles/main.scss";
import salesforceLogo from "../images/salesforce-partner-logo.png";
import EmailBlockV2 from "../components/EmailBlockV2";
import ImplementationLogo from "../images/salesforce/implementation.svg";
import customLogo from "../images/salesforce/custom.svg";
import integrationLogo from "../images/salesforce/integration.svg";
import migrationLogo from "../images/salesforce/migration.svg";
import trainingLogo from "../images/salesforce/training.svg";
import manageLogo from "../images/salesforce/manage.svg";
import supportLogo from "../images/salesforce/support.svg";
import consultingLogo from "../images/salesforce/consulting.svg";
import swiftLogo from "../images/salesforce/swift.svg";
import ServiceFacilities from "../components/ServiceFacilities";
import Testimonials from "../components/Testimonials";
// import Background from "../images/salesforce/salesforce-bg.jpg";
import LeadFormModal from "../components/LeadFormModal";
import { useState } from "react";
import { CSSTransition } from "react-transition-group";
import Seo from "../components/seo";
import SalesforceFooter from "../components/SalesforceFooter";

const SalesforceConsulting = () => {
    const [showModal, setShowModal] = useState(false);
    const testimonial = useStaticQuery(graphql`
        {
            allContentfulTestimonials(filter: { division: { name: { eq: "Salesforce" } } }) {
                nodes {
                    id
                    personDesignation
                    personName
                    profileImage {
                        gatsbyImageData(
                            quality: 100
                            sizes: "250"
                            layout: CONSTRAINED
                            placeholder: BLURRED
                        )
                        title
                    }
                    testimonialType
                    testimonialStatement {
                        testimonialStatement
                    }
                }
            }
        }
    `);

    const handleModel = () => {
        setShowModal(true);
    };

    const services = [
        {
            title: "Implementation Services",
            description:
                "Powered by our experience, industry expertise & business perspective, get an error-free,accelarated, result-driven Salesforce implementation for your project.",
            icon: ImplementationLogo,
            items: [
                "Requirements Gathering",
                "Solution Design",
                "Configuration and Coding",
                "Data Migration",
                "User Training",
            ],
        },
        {
            title: "Custom Development",
            description:
                "We create powerful, scalable, custom applications effortlessly, from easy setup to complex business process automation, using Lightning Platform.",
            icon: customLogo,
            items: ["Low Code & No Code", "Automated Workflows", "Lightning Components"],
        },
        {
            title: "Integration Services",
            description:
                "Seamlessly integrate with your existing apps – connect Salesforce to the tools essential for your business operations, from marketing to collaboration & beyond.",
            icon: integrationLogo,
            items: [
                "ERP Integration to Salesforce",
                "API Integration",
                "3rd Party Integration",
                "CRM to CRM Integration",
                "Data Migration & Data Governance",
            ],
        },
        {
            title: "Data Migration and Management",
            description:
                "Our experts bring together every system, data, wherever they run, including in legacy systems to Salesforce, ensuring the accurate & secure transfer of data.",
            icon: migrationLogo,
            items: ["Data Migration & Data Governance", "Classic to Lightning Platform Migration"],
        },
        {
            title: "Consulting and Strategy",
            description:
                "As a trusted Salesforce Ridge partner, we provide strategic consulting services to align Salesforce solutions with organizational goals and industry best practices",
            icon: consultingLogo,
            items: [
                "Discovery & Workshops",
                "Org Consolidation Service",
                "Assessments & Best Practice Service",
                "Business Improvements Service",
                "System Health Check",
            ],
        },
        {
            title: "Support and Maintenance",
            description:
                "Our unrivaled Salesforce consultants provide expert guidance, expertise & support at every stage of your journey- empowering you to focus on core business operations.",
            icon: supportLogo,
            items: [
                "Admin support & Maintenance",
                "On-going Enhancements & Support",
                "Deployment & Automation",
                "Org Optimization",
            ],
        },
        {
            title: "Training and User Adoption",
            description:
                "From guided learning paths to remote classes and workshops, everyone- in any role/ department can drive successful adoption of Salesforce within organizations.",
            icon: trainingLogo,
            items: [
                "User Support and Training",
                "Adoption and Change Management",
                "Process Optimization and Redesign",
            ],
        },
        {
            title: "Managed Services",
            description:
                "Start fast, power up your business with the most complete CRM starter suite. Win more deals, keep customers happy and connect email in a single app from day one.",
            icon: manageLogo,
            items: [
                "Sales Cloud Quickstart Package",
                "Service Cloud Quickstart package",
                "Community Cloud Quickstart Package",
                "Rapid Integration",
                "Pardot Quickstart Package",
                "Non profit Cloud",
                "Field Service Lightning",
                "Marketing Cloud Quickstart Package",
            ],
        },
        {
            title: "Swift Start Solution",
            description:
                "Take your Salesforce experience to the next level- manage all core operations, gain strategic insights and use industry best practices to keep business running.",
            icon: swiftLogo,
            items: [
                "Administration",
                "Configuration",
                "Data Hygiene",
                "Reporting",
                "Integrations",
                "Automation",
            ],
        },
    ];
    return (
        <>
            <Seo />
            <section className="salesforce-headerV2">
                <div className="container">
                    <div className="salesforce-header__grid">
                        <div className="salesforce-header__grid-logo">
                            <div className="site-logo">
                                <div className="logo-inner flex flex-ai-c">
                                    <div className="ring">
                                        <Logo />
                                    </div>
                                    <svg
                                        className="logo"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="100"
                                        height="32.338"
                                        viewBox="0 0 100 32.338"
                                    >
                                        <path
                                            d="M16.473-85.474c5.047,0,10.92-2.756,10.92-9.5,0-5.937-4.22-7.337-9.542-9.415-3.542-1.378-5.217-2.184-5.2-4.114.021-1.951,1.59-3.1,3.711-3.1,1.93,0,4.156.933,6.722,3.033l3.52-4.6a16,16,0,0,0-10.391-3.881c-5.535.064-9.9,3.86-9.9,8.822,0,5.81,4.665,7.973,9.331,9.606,3.732,1.294,5.365,2.1,5.365,4.368,0,1.908-1.484,3.265-4.432,3.265A10.555,10.555,0,0,1,8.9-94.846L5.234-90.329a15.553,15.553,0,0,0,11.238,4.856ZM49.3-96.628c0-6.213-4.177-10.772-10.412-10.772-6.022,0-10.518,4.5-10.518,10.942a10.515,10.515,0,0,0,10.92,10.984,11.288,11.288,0,0,0,9.161-4.008l-3.69-3.435a6.282,6.282,0,0,1-5.068,2.4,5.341,5.341,0,0,1-5.407-4.071H49.154a10.808,10.808,0,0,0,.148-2.036ZM39.082-102.4a4.5,4.5,0,0,1,4.581,3.711H34.311A4.671,4.671,0,0,1,39.082-102.4Zm27.55-15.415v13.168h-.106a8.216,8.216,0,0,0-6.4-2.757c-5.683,0-9.988,4.58-9.988,11.027,0,6.552,4.283,10.9,10.157,10.9A7.852,7.852,0,0,0,66.781-88.4h.127v2.566h5.407v-31.977Zm-5.3,27.248c-3.181,0-5.429-2.354-5.429-5.789,0-3.626,2.4-5.937,5.45-5.937a5.6,5.6,0,0,1,5.577,5.937,5.511,5.511,0,0,1-5.6,5.789Zm17.307-19.466a3.5,3.5,0,0,0,3.52-3.478,3.512,3.512,0,0,0-3.52-3.456,3.5,3.5,0,0,0-3.52,3.456A3.489,3.489,0,0,0,78.639-110.029ZM75.8-85.835H81.48V-107.04H75.8ZM97.537-107.4a8.838,8.838,0,0,0-6.807,3.1h-.064v-2.735H84.983v21.205h5.683V-96.5c0-3.775,1.93-5.789,4.814-5.789,2.63,0,4.071,1.251,4.071,5.3v11.175h5.683v-12.6c0-6.531-3.16-8.991-7.7-8.991Z"
                                            transform="translate(-5.234 117.812)"
                                        />
                                    </svg>
                                </div>
                            </div>
                            <div className="salesforce-logo">
                                <img src={salesforceLogo} alt="svg" />
                            </div>
                        </div>
                        <div className="salesforce-header__grid-btn">
                            <div className="sdn-salesforce-link " onClick={handleModel}>
                                Try Our 3-days Free Audit
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* salesforce-banner section  */}
            <section className="salesforce-banner">
                <div className="container container--pb">
                    <div className="salesforce-banner__grid gap-3x">
                        <div className="salesforce-banner__grid-item">
                            <h1 className="text-h1 text-fw-medium text-clr-primary gap-3x salesforce-heading salesforce-title">
                                Salesforce Consulting Services
                            </h1>
                            <p className="text-p3  text-clr-secondary text-fw-regular">
                                Are you faced with challenges like
                            </p>
                            <ul>
                                <li className="text-p3  text-clr-secondary text-fw-regular">
                                    High maintenance costs & scalability
                                </li>
                                <li className="text-p3  text-clr-secondary text-fw-regular">
                                    Complex solutions & technical debts
                                </li>
                                <li className="text-p3  text-clr-secondary text-fw-regular">
                                    Limited user adoption
                                </li>
                            </ul>
                            <p className="text-p3 gap-2x text-clr-secondary text-fw-regular">
                                We drive organizations through simplified Salesforce
                                implementations.
                            </p>
                            <EmailBlockV2
                                background="#ffffff"
                                title="Talk to our Salesforce Experts"
                                salesforce
                            />
                        </div>
                        <div>
                            <StaticImage
                                src="../images/salesforce/salesforce-banner.png"
                                alt="salesforce-banner"
                            />
                        </div>
                    </div>
                    <div className="salesforce-banner__clients">
                        <p className="text-p3 gap-1x  text-clr-secondary text-fw-regular">
                            We Work With Some Amazing Clients
                        </p>
                        <div className="salesforce-banner__clients-logo">
                            <StaticImage src="../images/salesforce/benz.png" alt="benz" />
                            <StaticImage src="../images/salesforce/toyota.png" alt="toyota" />
                            <StaticImage src="../images/salesforce/bsa.png" alt="bsa" />
                            <StaticImage src="../images/salesforce/lt.png" alt="lt" />
                            <StaticImage src="../images/salesforce/unleash.png" alt="unleash" />
                            <StaticImage src="../images/salesforce/lionsgate.png" alt="lionsgate" />
                            <StaticImage src="../images/salesforce/ge.png" alt="ge" />
                            <StaticImage src="../images/salesforce/dmg.png" alt="dmg" />
                            <StaticImage src="../images/salesforce/aroma.png" alt="aroma" />
                        </div>
                    </div>
                </div>
            </section>
            {/* our services section  */}
            <section>
                <div className="container container--py">
                    <ServiceFacilities
                        facilities={services}
                        salesforce
                        title="Our service offering"
                    />
                </div>
            </section>
            {/* our product expertise */}
            <section className="salesforce-expertise-v2">
                <div className="container container--py">
                    <div className="text">
                        <h2 className="text-h2 text-fw-medium text-clr-primary gap-3x salesforce-heading">
                            Our Product Expertise 
                        </h2>
                        <p className="text-p2 text-fw-regular text-clr-secondary gap-4x">
                            Our team knows exactly how to harness your capabilities to maximize your
                            ROI.
                        </p>
                    </div>
                    <div className="salesforce-expertise-v2__grid">
                        <div className="salesforce-expertise-v2__grid-item">
                            <svg
                                width="48"
                                height="48"
                                viewBox="0 0 48 48"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect width="48" height="48" rx="4" fill="#107CAD" />
                                <path
                                    d="M30.1438 34.1761V37.9201C30.1438 38.2081 30.3838 38.4481 30.6718 38.4481C30.7678 38.4481 30.9118 38.4001 30.9598 38.3521L38.2078 32.4481C38.3038 32.3521 38.3518 32.2081 38.3518 32.0641C38.3518 31.9201 38.2558 31.7761 38.1598 31.6801L30.9118 25.4881C30.8638 25.4401 30.7678 25.4401 30.6718 25.4401C30.3838 25.4401 30.1438 25.6801 30.1438 25.9681V29.9521C25.6798 31.1521 22.0318 27.2161 22.0318 27.2161C21.9358 27.1201 21.7918 27.0721 21.6478 27.0721C21.3598 27.0721 21.1198 27.3121 21.1198 27.6001V27.6961C22.9438 35.2321 30.1438 34.1761 30.1438 34.1761Z"
                                    fill="white"
                                />
                                <path
                                    d="M18.3358 28.2721L18.2398 27.9361V27.5041C18.2398 25.6321 19.7758 24.0961 21.6478 24.0961C22.5598 24.0961 23.3758 24.4321 24.0478 25.1041L24.1438 25.2001C24.5758 25.6801 25.8238 26.6881 27.3118 27.1201V25.9201C27.3118 24.0481 28.8478 22.5121 30.7198 22.5121C31.4398 22.5121 31.9198 22.7041 32.2558 22.8961L32.5918 23.0401L32.8798 23.2801L36.9598 26.7361C37.9198 25.7761 38.4958 24.4321 38.4958 22.9921C38.4958 20.0161 36.0958 17.6161 33.1198 17.6161H32.8318V17.1361C32.8318 14.1601 30.3838 11.7121 27.4078 11.7121C25.9678 11.7121 24.7198 12.2401 23.7598 13.1521C22.9918 11.1361 21.0238 9.6001 18.6718 9.6001C15.6958 9.6001 13.2478 12.0481 13.2478 15.0241C13.2478 16.0321 13.5358 16.9441 13.9678 17.7601C11.5678 18.3361 9.83984 20.4481 9.83984 23.0401C9.83984 25.9681 12.0958 28.3201 15.0238 28.4161H18.5758C18.3838 28.3681 18.3838 28.3201 18.3358 28.2721Z"
                                    fill="white"
                                />
                            </svg>
                            <p className="text-p3 text-fw-medium text-clr-primary">Sales Cloud</p>
                        </div>
                        <div className="salesforce-expertise-v2__grid-item">
                            <svg
                                width="48"
                                height="48"
                                viewBox="0 0 48 48"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect width="48" height="48" rx="4" fill="#1B96FF" />
                                <path
                                    d="M18.5428 27.4751C18.5199 27.8789 18.4424 28.2778 18.3124 28.6607L23.8372 34.4783C24.147 34.8047 24.3189 35.238 24.3172 35.6879C24.3347 35.9132 24.2956 36.1392 24.2033 36.3455C24.1111 36.5517 23.9687 36.7316 23.7892 36.8687C23.4312 37.1491 22.9869 37.2963 22.5324 37.2849C22.0778 37.2735 21.6414 37.1043 21.298 36.8063L15.7636 30.9887C15.3728 31.0979 14.9693 31.1544 14.5636 31.1567C13.9387 31.1858 13.3144 31.0884 12.728 30.8704C12.1417 30.6524 11.6054 30.3182 11.1514 29.8879C10.6973 29.4576 10.3348 28.9401 10.0856 28.3663C9.83643 27.7925 9.70566 27.1743 9.70117 26.5487C9.71894 26.1443 9.79659 25.7448 9.93157 25.3631C9.93157 25.1615 10.1332 25.1663 10.33 25.2719L12.754 27.9215C12.7883 27.9682 12.8331 28.0061 12.8848 28.0322C12.9365 28.0583 12.9937 28.072 13.0516 28.072C13.1095 28.072 13.1666 28.0583 13.2183 28.0322C13.27 28.0061 13.3148 27.9682 13.3492 27.9215L15.0868 26.2751C15.134 26.2303 15.1716 26.1763 15.1972 26.1165C15.2229 26.0567 15.2362 25.9922 15.2362 25.9271C15.2362 25.862 15.2229 25.7976 15.1972 25.7378C15.1716 25.678 15.134 25.624 15.0868 25.5791L12.6772 23.0399C12.5812 22.9391 12.5908 22.6415 12.7876 22.6463C13.1805 22.5481 13.5828 22.4918 13.9876 22.4783C14.63 22.4574 15.2693 22.5759 15.8614 22.8259C16.4535 23.0759 16.9844 23.4513 17.4174 23.9263C17.8504 24.4013 18.1753 24.9646 18.3696 25.5772C18.5638 26.1899 18.6229 26.8374 18.5428 27.4751Z"
                                    fill="white"
                                />
                                <path
                                    d="M37.762 16.6559C37.4812 15.6464 36.9905 14.7076 36.322 13.9007C35.547 12.9777 34.532 12.2867 33.3892 11.9039C32.7852 11.7027 32.1575 11.5816 31.522 11.5439C30.5605 11.4699 29.5949 11.6177 28.6996 11.9759C27.695 12.3831 26.7909 13.0039 26.05 13.7951C25.7524 14.1071 25.4836 14.4431 25.1908 14.7551L25.1236 14.6687C24.6849 14.0909 24.1826 13.5644 23.626 13.0991C23.0131 12.5883 22.3186 12.1842 21.5716 11.9039C20.6894 11.5814 19.7478 11.4537 18.8116 11.5295C17.8896 11.5896 16.9889 11.8327 16.162 12.2447C14.7639 12.9485 13.6553 14.1182 13.0276 15.5519C12.5463 16.6224 12.2804 17.7772 12.2452 18.9503C12.2231 19.5485 12.2666 20.1472 12.3748 20.7359L12.4948 20.7119C12.9787 20.6008 13.4721 20.5365 13.9684 20.5199H14.0596C14.9621 20.5066 15.8571 20.6851 16.6855 21.0435C17.5138 21.402 18.2566 21.9323 18.8647 22.5994C19.4727 23.2664 19.9322 24.055 20.2126 24.9129C20.4931 25.7708 20.5882 26.6785 20.4916 27.5759C20.4867 27.7367 20.4723 27.8969 20.4484 28.0559L25.2484 33.1199C25.7453 33.6399 26.079 34.294 26.2084 35.0015C28.1284 33.6206 29.9504 32.1082 31.6612 30.4751C32.966 29.2434 34.1668 27.9061 35.2516 26.4767C36.0158 25.475 36.6722 24.3954 37.21 23.2559C37.6766 22.2807 37.9767 21.2342 38.098 20.1599C38.2146 18.9812 38.1005 17.791 37.762 16.6559Z"
                                    fill="white"
                                />
                            </svg>
                            <p className="text-p3 text-fw-medium text-clr-primary">
                                Services Cloud
                            </p>
                        </div>
                        <div className="salesforce-expertise-v2__grid-item">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="48"
                                height="48"
                                viewBox="0 0 48 48"
                                fill="none"
                            >
                                <rect width="48" height="48" rx="4" fill="#FE8F7D" />
                                <path
                                    d="M22.0796 35.664L21.3116 35.04C20.4476 34.464 20.4476 33.264 20.4476 32.64V30.912C20.4476 30.432 20.0156 30 19.5356 30H15.9356C15.4556 30 15.0236 30.432 15.0236 30.912V35.52C15.0236 37.152 15.9836 38.4 17.4716 38.4H20.4476C22.1756 38.4 22.3196 37.2 22.3196 37.2C22.3196 37.2 22.6076 36.144 22.0796 35.664Z"
                                    fill="white"
                                />
                                <path
                                    d="M35.5196 19.248V11.088C35.5196 11.088 35.5196 11.088 35.5196 11.04C35.5196 9.60004 33.6956 9.16804 32.7356 10.128L27.3596 15.168C26.4956 15.888 25.4396 16.176 24.3356 16.176H15.2156C12.0476 16.224 9.59961 18.912 9.59961 22.032V22.128C9.59961 25.248 12.0476 27.6 15.2156 27.6H24.3836C25.5356 27.6 26.5916 28.08 27.4556 28.8L32.7356 33.936C33.6956 34.896 35.5196 34.512 35.5196 33.12C35.5196 33.12 35.5196 33.12 35.5196 33.072V24.96C37.3436 24.96 38.3996 23.712 38.3996 22.08C38.3996 20.496 37.2956 19.248 35.5196 19.248Z"
                                    fill="white"
                                />
                            </svg>
                            <p className="text-p3 text-fw-medium text-clr-primary">
                                Marketing Cloud
                            </p>
                        </div>
                        <div className="salesforce-expertise-v2__grid-item">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="48"
                                height="48"
                                viewBox="0 0 48 48"
                                fill="none"
                            >
                                <rect width="48" height="48" rx="4" fill="#396547" />
                                <path
                                    d="M36.5948 25.224C36.494 25.224 36.3884 25.2432 36.2972 25.2912C36.206 25.3296 36.1196 25.392 36.0428 25.4688L28.4348 33.8544C28.4348 33.8544 28.3772 33.9024 28.3484 33.912C28.3196 33.9216 28.2812 33.9312 28.2476 33.9312C28.1804 33.9312 28.1276 33.912 28.0796 33.864L25.4492 31.2816C25.382 31.2144 25.3004 31.1616 25.2044 31.1232C25.1132 31.0848 25.0172 31.0656 24.926 31.0656C24.8348 31.0656 24.7292 31.0848 24.6476 31.1232C24.5564 31.1616 24.4796 31.2144 24.4028 31.2816L23.3804 32.3376C23.3132 32.4048 23.2604 32.4864 23.222 32.5824C23.1836 32.6784 23.1644 32.7696 23.1644 32.8608C23.1644 32.952 23.1836 33.0576 23.222 33.1392C23.2604 33.2304 23.3132 33.3072 23.3804 33.384L27.4604 37.4016C27.6956 37.6272 28.0028 37.7472 28.3196 37.7472C28.4876 37.7472 28.6556 37.7088 28.814 37.6464C28.9724 37.584 29.102 37.4784 29.2172 37.3488L38.2028 27.4752C38.27 27.3984 38.3132 27.3168 38.3516 27.2304C38.39 27.1392 38.3996 27.0432 38.3996 26.952C38.3996 26.8608 38.3708 26.7648 38.3324 26.6736C38.294 26.5872 38.2316 26.5056 38.1644 26.4384L37.07 25.4448C36.9404 25.3152 36.7724 25.2384 36.5948 25.2288V25.224Z"
                                    fill="white"
                                />
                                <path
                                    d="M17.294 20.5104C16.6556 19.4976 16.2908 18.3024 16.2908 16.992C16.2908 14.784 17.2172 12.912 18.7052 11.7888C18.1436 10.8528 17.2172 10.2528 15.9164 10.2528C13.766 10.2528 12.5756 11.976 12.5756 13.9968C12.5756 15.0816 12.95 15.9792 13.6508 16.656C14.0636 17.0304 14.39 17.5152 14.39 18.0432C14.39 18.5712 14.2028 19.0176 12.9788 19.5408C11.2268 20.328 9.63801 21.336 9.59961 22.9872C9.59961 24.072 10.3004 24.8592 11.3132 24.8592H12.9116C13.1756 24.8592 13.4348 24.7104 13.55 24.4848C14.3276 23.064 15.7772 22.1616 17.006 21.5664C17.4188 21.3792 17.5676 20.8944 17.3036 20.52H17.294V20.5104Z"
                                    fill="white"
                                />
                                <path
                                    d="M31.4924 19.536C30.2684 19.0128 30.0812 18.5232 30.0812 18.0384C30.0812 17.5152 30.4172 17.0256 30.8204 16.6512C31.5212 15.9792 31.8956 15.0768 31.8956 13.992C31.8956 11.9712 30.7052 10.248 28.5548 10.248C27.254 10.248 26.3276 10.848 25.766 11.784C27.254 12.9072 28.1804 14.7408 28.1804 16.9872C28.1804 18.2976 27.8444 19.4976 27.1772 20.5056C26.9132 20.88 27.0668 21.3648 27.4748 21.5904C28.6988 22.1904 30.1532 23.088 30.9308 24.5088C31.0412 24.7344 31.3052 24.8832 31.5692 24.8832H33.1676C34.1708 24.8832 34.8812 24.096 34.8812 23.0112C34.8428 21.3648 33.2444 20.3136 31.502 19.5312H31.4924V19.536Z"
                                    fill="white"
                                />
                                <path
                                    d="M25.4636 23.1312C24.1244 22.5696 23.9372 22.0464 23.9372 21.4848C23.9372 20.9232 24.3116 20.3616 24.7532 19.9488C25.5308 19.2384 25.9772 18.1872 25.9772 16.992C25.9772 14.784 24.638 12.8736 22.262 12.8736C19.886 12.8736 18.5468 14.784 18.5468 16.992C18.5468 18.192 18.998 19.2 19.7708 19.9488C20.222 20.3616 20.5868 20.9232 20.5868 21.4848C20.5868 22.0464 20.3612 22.5696 19.0604 23.1312C17.0588 23.9568 15.1964 24.9264 15.158 26.7264C15.158 27.9264 16.0076 28.9728 17.1212 28.9728H27.3068C28.4204 28.9728 29.27 27.9264 29.27 26.7264C29.2316 24.9312 27.3788 23.9568 25.4396 23.1312H25.4636Z"
                                    fill="white"
                                />
                            </svg>
                            <p className="text-p3 text-fw-medium text-clr-primary">
                                Experience Cloud
                            </p>
                        </div>
                        <div className="salesforce-expertise-v2__grid-item">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="48"
                                height="48"
                                viewBox="0 0 48 48"
                                fill="none"
                            >
                                <rect width="48" height="48" rx="4" fill="#ACD360" />
                                <path
                                    d="M20.6405 13.8719C20.7845 14.2079 21.1205 14.3999 21.5045 14.3999H26.4005C26.7845 14.3999 27.1205 14.2079 27.2645 13.8719L29.1845 10.3199C29.3285 9.98395 29.0885 9.64795 28.7529 9.64795H19.2485C18.9125 9.64795 18.6725 10.0319 18.8165 10.3199L20.6405 13.8719Z"
                                    fill="white"
                                />
                                <path
                                    d="M26.8325 17.2799H21.1205C16.3685 17.2799 12.4805 21.168 12.4805 25.92V35.52C12.4805 37.104 13.7765 38.4 15.3605 38.4H32.5925C34.1765 38.4 35.5205 37.104 35.5205 35.52V25.92C35.5205 21.168 31.5845 17.2799 26.8325 17.2799ZM25.4405 33.4079V35.0399C25.4405 35.3279 25.1525 35.52 24.8645 35.52H22.9445C22.6565 35.52 22.5605 35.3279 22.5605 35.0399V33.4559C21.1205 33.1675 19.9205 32.5439 19.5845 32.2559C19.2005 31.9199 19.1045 31.584 19.3925 31.152L20.0165 30.1919C20.1605 29.952 20.4485 29.8075 20.7365 29.8075C20.9285 29.8075 21.0725 29.8559 21.2165 29.952H21.2645C22.2245 30.5275 23.0885 30.8159 23.7125 30.8159C24.3845 30.8159 24.9125 30.4799 24.9125 30.0959C24.9125 29.8075 24.7205 29.3275 22.8965 28.7039C21.2645 28.1279 19.2485 27.1199 19.2485 24.9124C19.2485 23.6164 20.1125 22.0804 22.5125 21.6004V20.1604C22.5125 19.8724 22.6565 19.6804 22.8965 19.6804H24.8165C25.1045 19.6804 25.3925 19.8724 25.3925 20.1604V21.6004C26.3525 21.8404 27.408 22.3204 27.744 22.5604C27.936 22.6564 28.0325 22.8964 28.08 23.1364C28.1275 23.3764 28.032 23.6164 27.888 23.7604L27.168 24.6244C26.9765 24.8644 26.64 25.0564 26.3525 25.0564C26.2085 25.056 26.0645 25.0079 25.9205 24.9599C24.9605 24.4319 24.1445 24.1439 23.6165 24.1439C22.8005 24.1439 22.4645 24.5279 22.4645 24.7679C22.4645 25.1039 22.6565 25.4879 24.2885 26.0639C26.3045 26.736 28.5605 27.7924 28.5605 30.0479C28.6085 31.5839 27.3605 32.976 25.4405 33.4079Z"
                                    fill="white"
                                />
                            </svg>
                            <p className="text-p3 text-fw-medium text-clr-primary">CPQ</p>
                        </div>
                        <div className="salesforce-expertise-v2__grid-item">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="48"
                                height="48"
                                viewBox="0 0 48 48"
                                fill="none"
                            >
                                <rect width="48" height="48" rx="4" fill="#FF5D2D" />
                                <path
                                    d="M18.2396 14.4001H20.1596C20.4476 14.4001 20.6396 14.2081 20.6396 13.9201V12.4801H27.3596V13.9201C27.3596 14.2081 27.5516 14.4001 27.8396 14.4001H29.7596C30.0476 14.4001 30.2396 14.2081 30.2396 13.9201V12.4801C30.2396 10.8961 28.9436 9.6001 27.3596 9.6001H20.6396C19.0556 9.6001 17.7596 10.8961 17.7596 12.4801V13.9201C17.7596 14.2081 17.9516 14.4001 18.2396 14.4001Z"
                                    fill="white"
                                />
                                <path
                                    d="M35.5196 17.2801H12.4796C10.8956 17.2801 9.59961 18.5761 9.59961 20.1601V35.5201C9.59961 37.1041 10.8956 38.4001 12.4796 38.4001H35.5196C37.1036 38.4001 38.3996 37.1041 38.3996 35.5201V20.1601C38.3996 18.5761 37.1036 17.2801 35.5196 17.2801ZM18.1916 22.6081H29.8556C30.2396 22.6081 30.2876 22.9441 30.1916 23.1361L24.2876 28.6081C24.1436 28.7521 23.9036 28.7521 23.7116 28.6081L17.8556 23.1841C17.6156 22.8961 17.8556 22.6081 18.1916 22.6081ZM30.2396 30.7201C30.2396 31.3921 29.4716 32.1601 28.7996 32.1601H19.1996C18.5276 32.1601 17.7596 31.3921 17.7596 30.7201C17.7596 30.7201 17.7596 27.1681 17.7596 25.4881C17.7596 25.2001 18.0956 25.0561 18.2876 25.2481L22.8956 29.5201C23.1836 29.8081 23.6156 29.9521 23.9996 29.9521C24.3836 29.9521 24.8156 29.8081 25.1036 29.5201L29.6636 25.2481C29.8556 25.0561 30.1916 25.1521 30.1916 25.3921C30.2396 25.8241 30.2396 30.5761 30.2396 30.7201Z"
                                    fill="white"
                                />
                            </svg>
                            <p className="text-p3 text-fw-medium text-clr-primary">Pardot</p>
                        </div>
                        <div className="salesforce-expertise-v2__grid-item">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="48"
                                height="48"
                                viewBox="0 0 48 48"
                                fill="none"
                            >
                                <rect width="48" height="48" rx="4" fill="#FF7B84" />
                                <path
                                    d="M26.0642 35.5679C24.9127 36.7679 23.0402 36.7679 21.8882 35.5679C18.4322 31.8724 11.8082 24.8164 11.8082 24.7679C8.83217 21.6479 8.83217 16.5119 11.8082 13.3919C13.2482 11.8559 15.1682 11.0399 17.2322 11.0399C19.2962 11.0399 21.2162 11.8559 22.6562 13.3919L23.2322 14.1119C23.6162 14.5919 24.3842 14.5919 24.7682 14.1119L25.2482 13.4879C25.2962 13.4399 25.2962 13.4399 25.3442 13.3919C26.8327 11.8079 28.7527 10.9919 30.7682 10.9919C32.8327 10.9919 34.7527 11.8079 36.1927 13.3439C39.1682 16.4639 39.1682 21.5999 36.1927 24.7199C36.1442 24.8159 29.5682 31.8719 26.0642 35.5679Z"
                                    fill="white"
                                />
                            </svg>
                            <p className="text-p3 text-fw-medium text-clr-primary">
                                NPSP (Nonprofits)
                            </p>
                        </div>
                        <div className="salesforce-expertise-v2__grid-item">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="48"
                                height="48"
                                viewBox="0 0 48 48"
                                fill="none"
                            >
                                <rect width="48" height="48" rx="4" fill="#6B9EE2" />
                                <path
                                    d="M21.6484 9.6001C20.4484 9.6001 19.3924 10.3201 18.9604 11.4241L13.9204 24.4321C13.5364 25.3921 14.2564 26.4001 15.2644 26.4001H23.5204L20.4484 37.1521C20.1604 38.1601 21.4084 38.8321 22.0804 38.0161L34.7044 23.0401C35.5204 22.1281 34.8484 20.6401 33.6004 20.6401H27.3604L32.8324 11.8081C33.4079 10.8481 32.7364 9.6001 31.5839 9.6001H21.6484Z"
                                    fill="white"
                                />
                            </svg>
                            <p className="text-p3 text-fw-medium text-clr-primary">FSL</p>
                        </div>
                        <div className="salesforce-expertise-v2__grid-item">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="48"
                                height="48"
                                viewBox="0 0 48 48"
                                fill="none"
                            >
                                <rect width="48" height="48" rx="4" fill="#0F1E3C" />
                                <path
                                    d="M28.7996 24.7681H24.8156V29.2801H23.2316V24.7681H19.1996V23.1841H23.1836V18.7201H24.7676V23.2321H28.7996V24.7681Z"
                                    fill="white"
                                />
                                <path
                                    d="M20.6396 30.3841H17.2316V26.4001H15.9356V30.3841H12.4796V31.5841H15.9356V35.5201H17.2316V31.5841H20.6396V30.3841Z"
                                    fill="white"
                                />
                                <path
                                    d="M35.5196 16.4161H32.0636V12.4801H30.7676V16.4161H27.3596V17.6641H30.8156V21.6001H32.1116V17.6641H35.5196V16.4161Z"
                                    fill="white"
                                />
                                <path
                                    d="M26.8796 34.4641H24.5756V31.6801H23.4716V34.4641H21.1196V35.5681H23.4716V38.4001H24.5756V35.5681H26.8796V34.4641Z"
                                    fill="white"
                                />
                                <path
                                    d="M20.6396 16.4161H17.1836V12.4801H15.9356V16.4161H12.4796V17.6161H15.9356V21.6001H17.1836V17.6161H20.6396V16.4161Z"
                                    fill="white"
                                />
                                <path
                                    d="M38.3996 23.4241H36.0956V20.6401H34.9916V23.4241H32.6396V24.5281H34.9916V27.3601H36.0956V24.5761H38.3996V23.4241Z"
                                    fill="white"
                                />
                                <path
                                    d="M35.5196 30.3841H32.0636V26.4001H30.7676V30.3841H27.3596V31.5841H30.8156V35.5201H32.1116V31.5841H35.5196V30.3841Z"
                                    fill="white"
                                />
                                <path
                                    d="M26.8796 12.3361H24.4316V9.6001H23.5196V12.3361H21.1196V13.1521H23.5676V15.8401H24.4796V13.1521H26.8796V12.3361Z"
                                    fill="white"
                                />
                                <path
                                    d="M15.3596 23.6161H12.9116V21.1201H11.9996V23.6161H9.59961V24.3841H12.0476V26.8801H12.9596V24.3841H15.3596V23.6161Z"
                                    fill="white"
                                />
                            </svg>
                            <p className="text-p3 text-fw-medium text-clr-primary">
                                Salesforce Tableau
                            </p>
                        </div>
                    </div>
                    <div className="btn">
                        <div className="sdn-salesforce-link" onClick={handleModel}>
                            Talk to our Salesforce Experts
                        </div>
                    </div>
                </div>
            </section>
            {/* meet our team section */}
            <section className="our-team">
                <div className="container container--py">
                    <h1 className="text-h2 text-clr-primary text-fw-medium gap-3x salesforce-heading">
                        Meet Our Team
                    </h1>
                    <div className="leaders__grid-v2-salesforce">
                        <div className="leaders__grid-v2-salesforce-item-v2">
                            <StaticImage
                                src="../images/salesforce/Frame-Manu.png"
                                alt="salesforce-model"
                                loading="lazy"
                                className="leader-img gap-1x"
                            />
                            <div className="leader-name-item">
                                <p className="leader-name text-p1 text-clr-primary text-fw-medium">
                                    Manu John
                                </p>
                                <a
                                    href="https://www.linkedin.com/in/manu-john-587a6a13/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="32"
                                        height="32"
                                        viewBox="0 0 32 32"
                                        fill="none"
                                    >
                                        <path
                                            d="M27 3H5C4.46957 3 3.96086 3.21071 3.58579 3.58579C3.21071 3.96086 3 4.46957 3 5V27C3 27.5304 3.21071 28.0391 3.58579 28.4142C3.96086 28.7893 4.46957 29 5 29H27C27.5304 29 28.0391 28.7893 28.4142 28.4142C28.7893 28.0391 29 27.5304 29 27V5C29 4.46957 28.7893 3.96086 28.4142 3.58579C28.0391 3.21071 27.5304 3 27 3ZM27 27H5V5H27V27ZM12 14V22C12 22.2652 11.8946 22.5196 11.7071 22.7071C11.5196 22.8946 11.2652 23 11 23C10.7348 23 10.4804 22.8946 10.2929 22.7071C10.1054 22.5196 10 22.2652 10 22V14C10 13.7348 10.1054 13.4804 10.2929 13.2929C10.4804 13.1054 10.7348 13 11 13C11.2652 13 11.5196 13.1054 11.7071 13.2929C11.8946 13.4804 12 13.7348 12 14ZM23 17.5V22C23 22.2652 22.8946 22.5196 22.7071 22.7071C22.5196 22.8946 22.2652 23 22 23C21.7348 23 21.4804 22.8946 21.2929 22.7071C21.1054 22.5196 21 22.2652 21 22V17.5C21 16.837 20.7366 16.2011 20.2678 15.7322C19.7989 15.2634 19.163 15 18.5 15C17.837 15 17.2011 15.2634 16.7322 15.7322C16.2634 16.2011 16 16.837 16 17.5V22C16 22.2652 15.8946 22.5196 15.7071 22.7071C15.5196 22.8946 15.2652 23 15 23C14.7348 23 14.4804 22.8946 14.2929 22.7071C14.1054 22.5196 14 22.2652 14 22V14C14.0012 13.7551 14.0923 13.5191 14.256 13.3369C14.4197 13.1546 14.6446 13.0388 14.888 13.0114C15.1314 12.9839 15.3764 13.0468 15.5765 13.188C15.7767 13.3292 15.918 13.539 15.9738 13.7775C16.6502 13.3186 17.4389 13.0526 18.2552 13.0081C19.0714 12.9637 19.8844 13.1424 20.6067 13.5251C21.329 13.9078 21.9335 14.48 22.3551 15.1803C22.7768 15.8806 22.9997 16.6825 23 17.5ZM12.5 10.5C12.5 10.7967 12.412 11.0867 12.2472 11.3334C12.0824 11.58 11.8481 11.7723 11.574 11.8858C11.2999 11.9994 10.9983 12.0291 10.7074 11.9712C10.4164 11.9133 10.1491 11.7704 9.93934 11.5607C9.72956 11.3509 9.5867 11.0836 9.52882 10.7926C9.47094 10.5017 9.50065 10.2001 9.61418 9.92597C9.72771 9.65189 9.91997 9.41762 10.1666 9.2528C10.4133 9.08797 10.7033 9 11 9C11.3978 9 11.7794 9.15804 12.0607 9.43934C12.342 9.72064 12.5 10.1022 12.5 10.5Z"
                                            fill="black"
                                        />
                                    </svg>
                                </a>
                            </div>
                            <p className="text-p4 text-clr-secondary text-fw-medium">
                                Practice Head
                            </p>
                            <div className="contact-info">
                                <div className="leader-phone text-p4 text-clr-secondary text-fw-medium">
                                    <StaticImage
                                        src="../images/salesforce/phone.svg"
                                        alt="salesforce-phone"
                                        loading="lazy"
                                        className="leader-phone-svg"
                                        style={{ marginRight: "10px" }}
                                    />
                                    +91 98957 58118
                                </div>
                                <div className="leader-email text-p4 text-clr-secondary text-fw-medium">
                                    <StaticImage
                                        src="../images/salesforce/mail.svg"
                                        alt="salesforce-phone"
                                        loading="lazy"
                                        className="leader-mail-svg"
                                        style={{ marginRight: "10px" }}
                                    />
                                    manu@sedintechnologies.com
                                </div>
                            </div>
                        </div>
                        <div className="leaders__grid-v2-salesforce-item-v2">
                            <StaticImage
                                src="../images/salesforce/Frame-Guhan.png"
                                alt="salesforce-model"
                                loading="lazy"
                                className="leader-img gap-1x"
                            />
                            <div className="leader-name-item">
                                <p className="leader-name text-p1 text-clr-primary text-fw-medium">
                                    Guhan Elumalai
                                </p>
                                <a
                                    href="https://www.linkedin.com/in/guhane/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="32"
                                        height="32"
                                        viewBox="0 0 32 32"
                                        fill="none"
                                    >
                                        <path
                                            d="M27 3H5C4.46957 3 3.96086 3.21071 3.58579 3.58579C3.21071 3.96086 3 4.46957 3 5V27C3 27.5304 3.21071 28.0391 3.58579 28.4142C3.96086 28.7893 4.46957 29 5 29H27C27.5304 29 28.0391 28.7893 28.4142 28.4142C28.7893 28.0391 29 27.5304 29 27V5C29 4.46957 28.7893 3.96086 28.4142 3.58579C28.0391 3.21071 27.5304 3 27 3ZM27 27H5V5H27V27ZM12 14V22C12 22.2652 11.8946 22.5196 11.7071 22.7071C11.5196 22.8946 11.2652 23 11 23C10.7348 23 10.4804 22.8946 10.2929 22.7071C10.1054 22.5196 10 22.2652 10 22V14C10 13.7348 10.1054 13.4804 10.2929 13.2929C10.4804 13.1054 10.7348 13 11 13C11.2652 13 11.5196 13.1054 11.7071 13.2929C11.8946 13.4804 12 13.7348 12 14ZM23 17.5V22C23 22.2652 22.8946 22.5196 22.7071 22.7071C22.5196 22.8946 22.2652 23 22 23C21.7348 23 21.4804 22.8946 21.2929 22.7071C21.1054 22.5196 21 22.2652 21 22V17.5C21 16.837 20.7366 16.2011 20.2678 15.7322C19.7989 15.2634 19.163 15 18.5 15C17.837 15 17.2011 15.2634 16.7322 15.7322C16.2634 16.2011 16 16.837 16 17.5V22C16 22.2652 15.8946 22.5196 15.7071 22.7071C15.5196 22.8946 15.2652 23 15 23C14.7348 23 14.4804 22.8946 14.2929 22.7071C14.1054 22.5196 14 22.2652 14 22V14C14.0012 13.7551 14.0923 13.5191 14.256 13.3369C14.4197 13.1546 14.6446 13.0388 14.888 13.0114C15.1314 12.9839 15.3764 13.0468 15.5765 13.188C15.7767 13.3292 15.918 13.539 15.9738 13.7775C16.6502 13.3186 17.4389 13.0526 18.2552 13.0081C19.0714 12.9637 19.8844 13.1424 20.6067 13.5251C21.329 13.9078 21.9335 14.48 22.3551 15.1803C22.7768 15.8806 22.9997 16.6825 23 17.5ZM12.5 10.5C12.5 10.7967 12.412 11.0867 12.2472 11.3334C12.0824 11.58 11.8481 11.7723 11.574 11.8858C11.2999 11.9994 10.9983 12.0291 10.7074 11.9712C10.4164 11.9133 10.1491 11.7704 9.93934 11.5607C9.72956 11.3509 9.5867 11.0836 9.52882 10.7926C9.47094 10.5017 9.50065 10.2001 9.61418 9.92597C9.72771 9.65189 9.91997 9.41762 10.1666 9.2528C10.4133 9.08797 10.7033 9 11 9C11.3978 9 11.7794 9.15804 12.0607 9.43934C12.342 9.72064 12.5 10.1022 12.5 10.5Z"
                                            fill="black"
                                        />
                                    </svg>
                                </a>
                            </div>
                            <p className="text-p4 text-clr-secondary text-fw-medium">
                                Head- Business Development- North America
                            </p>
                            <div className="contact-info">
                                <div className="leader-phone text-p4 text-clr-secondary text-fw-medium">
                                    <StaticImage
                                        src="../images/salesforce/phone.svg"
                                        alt="salesforce-phone"
                                        loading="lazy"
                                        className="leader-phone-svg"
                                        style={{ marginRight: "10px" }}
                                    />
                                    +1 (506) 897-2921
                                </div>
                                <div className="leader-email text-p4 text-clr-secondary text-fw-medium">
                                    <StaticImage
                                        src="../images/salesforce/mail.svg"
                                        alt="salesforce-phone"
                                        loading="lazy"
                                        className="leader-mail-svg"
                                        style={{ marginRight: "10px" }}
                                    />
                                    guhan@sedintechnologies.com
                                </div>
                            </div>
                        </div>
                        <div className="leaders__grid-v2-salesforce-item-v2">
                            <StaticImage
                                src="../images/salesforce/Frame-radhika.png"
                                alt="salesforce-model"
                                loading="lazy"
                                className="leader-img gap-1x"
                            />
                            <div className="leader-name-item">
                                <p className="leader-name text-p1 text-clr-primary text-fw-medium">
                                    Radhika Bauerle
                                </p>
                                <a
                                    href="https://www.linkedin.com/in/radhika-bauerle-984274199/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="32"
                                        height="32"
                                        viewBox="0 0 32 32"
                                        fill="none"
                                    >
                                        <path
                                            d="M27 3H5C4.46957 3 3.96086 3.21071 3.58579 3.58579C3.21071 3.96086 3 4.46957 3 5V27C3 27.5304 3.21071 28.0391 3.58579 28.4142C3.96086 28.7893 4.46957 29 5 29H27C27.5304 29 28.0391 28.7893 28.4142 28.4142C28.7893 28.0391 29 27.5304 29 27V5C29 4.46957 28.7893 3.96086 28.4142 3.58579C28.0391 3.21071 27.5304 3 27 3ZM27 27H5V5H27V27ZM12 14V22C12 22.2652 11.8946 22.5196 11.7071 22.7071C11.5196 22.8946 11.2652 23 11 23C10.7348 23 10.4804 22.8946 10.2929 22.7071C10.1054 22.5196 10 22.2652 10 22V14C10 13.7348 10.1054 13.4804 10.2929 13.2929C10.4804 13.1054 10.7348 13 11 13C11.2652 13 11.5196 13.1054 11.7071 13.2929C11.8946 13.4804 12 13.7348 12 14ZM23 17.5V22C23 22.2652 22.8946 22.5196 22.7071 22.7071C22.5196 22.8946 22.2652 23 22 23C21.7348 23 21.4804 22.8946 21.2929 22.7071C21.1054 22.5196 21 22.2652 21 22V17.5C21 16.837 20.7366 16.2011 20.2678 15.7322C19.7989 15.2634 19.163 15 18.5 15C17.837 15 17.2011 15.2634 16.7322 15.7322C16.2634 16.2011 16 16.837 16 17.5V22C16 22.2652 15.8946 22.5196 15.7071 22.7071C15.5196 22.8946 15.2652 23 15 23C14.7348 23 14.4804 22.8946 14.2929 22.7071C14.1054 22.5196 14 22.2652 14 22V14C14.0012 13.7551 14.0923 13.5191 14.256 13.3369C14.4197 13.1546 14.6446 13.0388 14.888 13.0114C15.1314 12.9839 15.3764 13.0468 15.5765 13.188C15.7767 13.3292 15.918 13.539 15.9738 13.7775C16.6502 13.3186 17.4389 13.0526 18.2552 13.0081C19.0714 12.9637 19.8844 13.1424 20.6067 13.5251C21.329 13.9078 21.9335 14.48 22.3551 15.1803C22.7768 15.8806 22.9997 16.6825 23 17.5ZM12.5 10.5C12.5 10.7967 12.412 11.0867 12.2472 11.3334C12.0824 11.58 11.8481 11.7723 11.574 11.8858C11.2999 11.9994 10.9983 12.0291 10.7074 11.9712C10.4164 11.9133 10.1491 11.7704 9.93934 11.5607C9.72956 11.3509 9.5867 11.0836 9.52882 10.7926C9.47094 10.5017 9.50065 10.2001 9.61418 9.92597C9.72771 9.65189 9.91997 9.41762 10.1666 9.2528C10.4133 9.08797 10.7033 9 11 9C11.3978 9 11.7794 9.15804 12.0607 9.43934C12.342 9.72064 12.5 10.1022 12.5 10.5Z"
                                            fill="black"
                                        />
                                    </svg>
                                </a>
                            </div>
                            <p className="text-p4 text-clr-secondary text-fw-medium">
                                Sedin- Salesforce Practice- North America
                            </p>
                            <div className="contact-info">
                                <div className="leader-phone text-p4 text-clr-secondary text-fw-medium">
                                    <StaticImage
                                        src="../images/salesforce/phone.svg"
                                        alt="salesforce-phone"
                                        loading="lazy"
                                        className="leader-phone-svg"
                                        style={{ marginRight: "10px" }}
                                    />
                                    +1 (408) 355-8462
                                </div>
                                <div className="leader-email text-p4 text-clr-secondary text-fw-medium">
                                    <StaticImage
                                        src="../images/salesforce/mail.svg"
                                        alt="salesforce-phone"
                                        loading="lazy"
                                        className="leader-mail-svg"
                                        style={{ marginRight: "10px" }}
                                    />
                                    radhika.b@sedintechnologies.com
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="salesforce-message container--py">
                <div
                    className="wrapper"
                    style={{
                        // background: `url(${Background})`,
                        background: "#1CA3DC",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    <div className="container">
                        <div className="salesforce-message__grid">
                            <h2 className="text-h3 text-fw-medium ">
                                Unlock the full potential of Salesforce for your organisation
                            </h2>
                            <div className="btn">
                                <a href="#campaign-contact" className="white-salesforce-link ">
                                    Talk to our Salesforce Experts
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* certified partner logo section */}
            <section className="service-partners-salesforce" style={{ paddingBottom: "0" }}>
                <div className="container container--py">
                    <div className="text">
                        <h2 className="text-h2 text-fw-medium text-clr-primary gap-3x salesforce-heading">
                            We’re a Certified Salesforce Partner
                        </h2>
                        <p className="text-p2 text-fw-regular text-clr-secondary gap-4x">
                            Leveraging Salesforce’s multi-cloud solutions, our certified and
                            experienced consultants have been solving our clients' most complex
                            business problems.
                        </p>
                    </div>
                    <div className="partners-grid">
                        <div className="partner-logo">
                            <StaticImage
                                src="../images/salesforce/javascript.png"
                                placeholder="blurred"
                                alt="clients"
                                className="gap-05x"
                                loading="lazy"
                            />
                            <p className="text-p2 text-clr-secondary text-fw-medium">
                                JavaScript Developer 1
                            </p>
                        </div>
                        <div className="partner-logo">
                            <StaticImage
                                src="../images/salesforce/platform-1.png"
                                placeholder="blurred"
                                alt="clients"
                                className="gap-05x"
                                loading="lazy"
                            />
                            <p className="text-p2 text-clr-secondary text-fw-medium">
                                Platform Developer 1
                            </p>
                        </div>
                        <div className="partner-logo">
                            <StaticImage
                                src="../images/salesforce/System-Architect.png"
                                placeholder="blurred"
                                alt="clients"
                                className="gap-05x"
                                loading="lazy"
                            />
                            <p className="text-p2 text-clr-secondary text-fw-medium">
                                System Architect
                            </p>
                        </div>
                        <div className="partner-logo">
                            <StaticImage
                                src="../images/salesforce/application.png"
                                placeholder="blurred"
                                alt="clients"
                                className="gap-05x"
                                loading="lazy"
                            />
                            <p className="text-p2 text-clr-secondary text-fw-medium">
                                Application Architect
                            </p>
                        </div>
                        <div className="partner-logo">
                            <StaticImage
                                src="../images/salesforce/visibility-architect.png"
                                placeholder="blurred"
                                alt="clients"
                                className="gap-05x"
                                loading="lazy"
                            />
                            <p className="text-p2 text-clr-secondary text-fw-medium">
                                Sharing and Visibility Architect
                            </p>
                        </div>
                        <div className="partner-logo">
                            <StaticImage
                                src="../images/salesforce/app-builder.png"
                                placeholder="blurred"
                                alt="clients"
                                className="gap-05x"
                                loading="lazy"
                            />
                            <p className="text-p2 text-clr-secondary text-fw-medium">
                                Platform App Builder
                            </p>
                        </div>
                        <div className="partner-logo">
                            <StaticImage
                                src="../images/salesforce/cpq.png"
                                placeholder="blurred"
                                alt="clients"
                                className="gap-05x"
                                loading="lazy"
                            />
                            <p className="text-p2 text-clr-secondary text-fw-medium">
                                CPQ Specialist
                            </p>
                        </div>
                        <div className="partner-logo">
                            <StaticImage
                                src="../images/salesforce/admin.png"
                                placeholder="blurred"
                                alt="clients"
                                className="gap-05x"
                                loading="lazy"
                            />
                            <p className="text-p2 text-clr-secondary text-fw-medium">
                                Administrator
                            </p>
                        </div>
                        <div className="partner-logo">
                            <StaticImage
                                src="../images/salesforce/tableau.png"
                                placeholder="blurred"
                                alt="clients"
                                className="gap-05x"
                                loading="lazy"
                            />
                            <p className="text-p2 text-clr-secondary text-fw-medium">
                                Tableau Consultant
                            </p>
                        </div>
                        <div className="partner-logo">
                            <StaticImage
                                src="../images/salesforce/experience-cloud.png"
                                placeholder="blurred"
                                alt="clients"
                                className="gap-05x"
                                loading="lazy"
                            />
                            <p className="text-p2 text-clr-secondary text-fw-medium">
                                Experience Cloud Consultant
                            </p>
                        </div>
                        <div className="partner-logo">
                            <StaticImage
                                src="../images/salesforce/service-cloud.png"
                                placeholder="blurred"
                                alt="clients"
                                className="gap-05x"
                                loading="lazy"
                            />
                            <p className="text-p2 text-clr-secondary text-fw-medium">
                                Service Cloud Consultant
                            </p>
                        </div>
                        <div className="partner-logo">
                            <StaticImage
                                src="../images/salesforce/sales-cloud.png"
                                placeholder="blurred"
                                alt="clients"
                                className="gap-05x"
                                loading="lazy"
                            />
                            <p className="text-p2 text-clr-secondary text-fw-medium">
                                Sales Cloud Consultant
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            {/* testimonial section */}
            <section className="salesforce-testimonial">
                <div className="container container--py">
                    <Testimonials testimonialData={testimonial.allContentfulTestimonials.nodes} />
                </div>
            </section>
            {/* ready to move section  */}
            <section className="salesforce-FCT">
                <section className="global-campaign-contact" id="campaign-contact">
                    <div className="container">
                        <div className="global-campaign-contact__text">
                            <div>
                                <h2 className="text-h2 gap-1x  text-fw-medium salesforce-heading">
                                    Ready to move beyond CX to exceptional experiences?
                                </h2>
                                {/* <p className="text-p2  text-clr-secondary text-fw-regular">
                                    Speak with a Sedin Salesforce consultant today to drive your
                                    transformational journey.
                                </p> */}
                            </div>
                            <EmailBlockV2
                                FooterCallToAction
                                background="#f8f9fa"
                                title="Talk to our Salesforce Experts"
                                salesforce
                            />
                        </div>
                    </div>
                </section>
            </section>
            <CSSTransition
                in={showModal}
                timeout={300} // Duration of the transition in milliseconds
                classNames="fade" // CSS class prefix for the transition styles
                unmountOnExit
            >
                <LeadFormModal showModal={showModal} setShowModal={setShowModal} salesforce />
            </CSSTransition>
            {/* footer section  */}
            <section>
                <SalesforceFooter />
            </section>
        </>
    );
};

export default SalesforceConsulting;
